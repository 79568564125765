<script setup>
import {computed, ref, onMounted, watch, provide} from "vue";
import {useStore} from "vuex";
import {RouterLink, RouterView, useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import {get, post} from "../service";
import Navbar from "@/components/Navbar.vue";
import LeftSidebar from "@/components/LeftSidebar.vue";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import _ from "lodash";
import router from "../router";

const route = useRoute();
const {t} = useI18n();

const store = useStore();

const active = ref(false);
const navItems = ref([
  {
    link: "ISF",
    icon: "ISFPOSM",
    name: t('leftSidebar.isf'),
    withChildren: true,
    children: [
      {
        link: "ISF/Equipment",
        icon: "Equipment",
        name: t('leftSidebar.equipment'),
        children: [
          {
            link: "ISF/Equipment/Delivery-Execution",
            name: t('leftSidebar.deliveryexecution'),
          },
          {
            link: "ISF/Equipment/Comparison",
            name: t('leftSidebar.comparison'),
          },
          {
            link: "ISF/Equipment/Progress",
            name: t('leftSidebar.progress'),
          },
        ],
      },
      {
        link: "ISF/Headers",
        icon: "Headers",
        name: t('leftSidebar.headers'),
        children: [
          {
            link: "ISF/Headers/Delivery-Execution",
            name: t('leftSidebar.deliveryexecution'),
          },
          {
            link: "ISF/Headers/Comparison",
            name: t('leftSidebar.comparison'),
          },
          // {
          //   link: "ISF/Headers/Progress",
          //   name: t('leftSidebar.progress'),
          // },
        ],
      },
      {
        link: "ISF/CycleMaterials",
        icon: "CycleMaterials",
        name: t('leftSidebar.cycleMaterials'),
        children: [
          {
            link: "ISF/CycleMaterials/Delivery-Execution",
            name: t('leftSidebar.deliveryexecution'),
          },
          {
            link: "ISF/CycleMaterials/Comparison",
            name: t('leftSidebar.comparison'),
          },
          // {
          //     link: "ISF/CycleMaterials/Progress",
          //     name: t('leftSidebar.progress'),
          // },
        ],
      },
      {
        link: "ISF/Semi-permanentMaterials",
        icon: "Semi-permanentMaterials",
        name: t('leftSidebar.semipermanentMaterials'),
        children: [
          {
            link: "ISF/Semi-permanentMaterials/Delivery-Execution",
            name: t('leftSidebar.deliveryexecution'),
          },
          {
            link: "ISF/Semi-permanentMaterials/Comparison",
            name: t('leftSidebar.comparison'),
          },
          // {
          //   link: "ISF/Semi-permanentMaterials/Progress",
          //   name: t('leftSidebar.progress'),
          // },
        ],
      },
      {
        link: "ISF/PremiumPartners",
        icon: "PremiumPartners",
        // disabled: true,
        name: t('leftSidebar.premiumPartners'),
        children: [
          {
            link: "ISF/PremiumPartners/Delivery-Execution",
            name: t('leftSidebar.deliveryexecution'),
          },
          {
            link: "ISF/PremiumPartners/Comparison",
            name: t('leftSidebar.comparison'),
          },
        ]
      },
    ]
  },
  {
    link: "POSM",
    name: t('leftSidebar.posm'),
    withChildren: true,
    children: [
      {
        link: "POSM/CAPEX-Depts",
        icon: "CAPEX-Depts",
        name: t('leftSidebar.capexdepts'),
      },
      {
        link: "POSM/OPEX-Depts",
        icon: "OPEX-Depts",
        name: t('leftSidebar.opexdepts'),
      },
      {
        link: "POSM/Stock",
        icon: "Stock",
        name: t('leftSidebar.stock'),
      },
      {
        link: "POSM/Transits",
        icon: "Transits",
        name: t('leftSidebar.transits'),
      },
    ],
  },
  {
    link: "Projects",
    name: "Projects",
    disabled: true,
    children: [
      {
        link: "Catalog",
        name: "Projects Catalog",
      },
      {
        link: "Delivery-Execution",
        name: t('leftSidebar.deliveryexecution'),
      },
      {
        link: "Comparison",
        name: t('leftSidebar.comparison'),
      },
      {
        link: "Progress",
        name: t('leftSidebar.progress'),
      },
    ],
  },
])
const navItemsBuffer = ref([...navItems.value]);
const multiselects = ref([])
const updatedItems = ref([])

const clicked = ref(false)
const modal = ref()
let menuActive = ref(false)

const navActive = (data) => {
  menuActive.value = data.menuActive;
}
const updateItems = (key, value) => {
  const index = navItems.value.findIndex(({name}) => name === key);

  navItems.value.splice(index, 1, {
    ...navItems.value[index],
    itemValue: value,
  });

  switch (key) {
    case 'Semi-permanent materials':
      store.dispatch('setTouchpointFilter', {property: 'touchpoints_name', value});
      switch (this.$route.path) {
        case '/panel/Touchpoints/Delivery-Execution':
          store.dispatch('loadTouchpointDeliveryExecution');
          break;
        case '/panel/Touchpoints/Comparison':
          store.dispatch('loadTouchpointComparison');
          break;
        case '/panel/Touchpoints/Progress':
          store.dispatch('loadTouchpointProgress');
          break;
      }
      break;
    case 'Projects':
      store.dispatch('setProjectsFilter', {property: 'project_name', value});
      switch (this.$route.path) {
        case '/panel/Projects/Delivery-Execution':
          store.dispatch('loadProjectsDeliveryExecution');
          break;
        case '/panel/Projects/Comparison':
          store.dispatch('loadProjectsComparison');
          break;
        case '/panel/Projects/Progress':
          store.dispatch('loadProjectsProgress');
          break;
      }
      break;
    case 'Cycle Materials':
      store.dispatch('setCycleFilter', {property: 'name', value});
      switch (this.$route.path) {
        case '/panel/CycleMaterials/Delivery-Execution':
          store.dispatch('loadCycleDeliveryExecution');
          break;
        case '/panel/CycleMaterials/Comparison':
          store.dispatch('loadCycleComparison');
          break;
        case '/panel/CycleMaterials/Progress':
          store.dispatch('loadCycleProgress');
          break;
      }
      break;
    default:
      break;
  }
}
const filterItems = (key, value) => {
  if(value === 'Dashboard') navItems.value = navItemsBuffer.value
  else navItems.value = [...navItemsBuffer.value.filter(e => e[key] === value)]
}

provide('navItems', navItems)
provide('updateItems', updateItems)
provide('filterItems', filterItems)

const userName = () => {
  if (store.getters.getUser) {
    const user = store.getters.getUser.user;
    if (user) {
      return [/* '[', _.get(user, 'id'), '] ', */ _.get(user, 'first_name'),
        ' ', _.get(user, 'last_name')].join('')
    }
  }
  return '';
};

provide('navItems', navItems)
provide('updateItems', updateItems)
provide('filterItems', filterItems)

// показываем модалку с уведомлением один раз, сохраняя в localStorage
onMounted(() => {
  // const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/filters' : 'dashboard/filters'
  //
  // get(url).then(response => {
  //   if (response.data.success) {
  //     const filter = _.get(response.data, 'filters');
  //     store.dispatch('setGlobalFilters', filter)
  //   }
  // })
  // watch(() => store.getters.getAlert, (value) => {
  //   if (+value.id > 0) {
  //     modal.value.show();
  //   }
  // })
})

</script>

<template lang="pug">
Navbar(:navActive="navActive" @activate="active = !active")
LeftSidebar(:navActive="active" @activate="active = !active")
ModalConstructor(modalName="AlarmExpress", ref="modal", :dialog="true", :id="$store.getters.getAlert.id", :title="$store.getters.getAlert.title", :msg="$store.getters.getAlert.message")
main
    .container
        Breadcrumbs(v-if="!($route.path === '/panel/Dashboard' || $route.path === '/panel/Projects/Catalog')")
        RouterView
</template>

<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 0.2s ease-in .1s;
}

.slide-fade-leave-active {
  transition: opacity 0.2s ease-in 0s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s ease 1s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(40px);
  opacity: 0;
}

</style>
