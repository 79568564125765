<script setup>

import { ref } from 'vue'
import Report from '@/components/Modals/ReportDownload/Report.vue'
import {useStore} from "vuex";
const store = useStore();

const reports = store.getters.reports

const emits = defineEmits(["hide"])

store.dispatch('setReadReport', reports.map(report => report.id))

</script>

<template lang="pug">
    
h2 {{ $t('reports') }}
.report-list
    Report(v-for="report in reports" :data="report")

</template>

<style lang="scss" scoped>

</style>