export default {
    dashboard: 'Dashboard',
    Dashboard: 'Dashboard',
    reports: 'Reports',
    no: 'No',
    yes: 'Yes',
    MINI: 'MINI',
    Executed: 'Executed',
    'Not Executed': 'Not Executed',
    "Delivered to TMR": 'Delivered to TMR',
    "Transit to TMR": 'Transit to TMR',
    "Delivered to City": 'Delivered to City',
    "Delivered to city": 'Delivered to City',
    "Transit to city": 'Transit to city',
    "Not Delivered": 'Not Delivered',
    Delivered: 'Delivered',
    'Not Delivered to CS': 'Not Delivered to CS',
    'Delivered to CS': 'Delivered to CS',
    Target: 'Target',
    total: 'Total',
    Total: 'Total',
    Potential: 'Potential',
    Stock: 'Stock',
    transit: "Transit",
    Transit: "Transit",
    'In Stock': 'In Stock',
    'Central storage': 'Central storage',
    'No Stock': 'No Stock',
    '>90 D': '>90 D',
    Current: 'Current',
    '>90 d': '>90 d',
    '>1 Year': '>1 Year',
    '3-12 Month': '3-12 Month',
    '>1 year': '>1 year',
    New: 'New',
    Overdue: 'Overdue',
    Work: 'Work',
    Promo: 'Promo',
    Mol: 'Mol',
    TMR: 'TMR',
    'TYPE: Equipment': 'Type: Equipment',
    'TYPE: Parts': 'Type: Parts',
    'TYPE: Souvenirs': 'Type: Souvenirs',
    'TYPE: POSM': 'Type: POSM',

    export: {
        title: 'Export Request',
        highlight: 'This process may take some time',
        text: 'Do you want to save the report in Excel?'
    },
    header: {
        logo: 'Merchandising Dashboard',
        menu: 'Menu',
        close: 'Close',
    },
    login: {
        login: 'Login',
        inputs: {
            login: 'Login',
            password: 'Password',
        },
        errors: {
            login: 'Login is required',
            password: 'Password is required',
        },
        submit: 'Login',
    },
    isf: 'ISF',
    ISF: 'ISF',
    Projects: 'Projects',
    CycleMaterials: 'Cycle Materials',
    'Cycle Materials': 'Cycle Materials',
    'Semi-permanent materials': 'Semi-permanent materials',
    'Equipment in PP': 'Equipment in PP',
    Headers: 'Headers',
    posm: 'POSM Balance',

    'Delivery / Execution': 'Delivery / Execution',
    Comparison: 'Comparison',
    Progress: 'Progress',
    capexdepts: 'CAPEX depts',
    opexdepts: 'OPEX depts',
    Unit: 'Unit',
    Region: 'Region',
    City: 'City',
    CM: 'CM',
    stock: 'STOCK',
    transits: "TRANSITS",
    ClearFilter: 'Clear Filter',
    Loading: 'Loading...',
    Type: 'Type',
    MULTICATEGORY: 'MULTICATEGORY',
    OTHER: 'OTHER',
    ALL: 'ALL',
    'Must set': 'Must set total',
    'MUST SET': 'Must set total',
    'Must set total': 'Must set total',
    'Other total': 'Other total',
    'Total qty': 'Total qty',
    Week: 'Week',
    Image: 'Image',
    'Execution status': 'Execution status',
    outlet: 'outlet',
    equip: 'equip',
    'Total BWD Multicategory': 'Total BWD Multicategory',
    'Total Vitrine / Taylor made': 'Total Vitrine / Taylor made',
    'Total BWD + Vitrine': 'Total BWD + Vitrine',
    'Attention!': 'Attention!',
    "The report is being prepared. Download the report by clicking on the icon": 'The report is being prepared. Download the report by clicking on the icon',
    'Start typing or select...': 'Start typing or select...',
    'CYCLE': 'CYCLE',
    'CYCLE_Selection': 'Selection',
    'ACTIVITY': 'ACTIVITY',
    'ACTIVITY_Selection': 'Selection',
    'Detailed summary': 'Detailed summary',
    leftSidebar: {
        dashboard: 'Dashboard',
        isf: 'ISF / POSM',
        semipermanentMaterials: 'Semi-permanent materials',
        equipment: 'Equipment',
        headers: 'Headers',
        posm: 'POSM Balance',
        lightbox: 'Lightbox',
        deliveryexecution: 'Delivery / Execution',
        cycleMaterials: "Cycle Materials",
        comparison: 'Comparison',
        progress: 'Progress',
        capexdepts: 'CAPEX depts',
        opexdepts: 'OPEX depts',
        stock: 'STOCK',
        transits: "TRANSITS",
        premiumPartners: "Premium Partners",
    },

}
