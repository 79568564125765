export default {
    dashboard: 'Дашборд',
    Dashboard: 'Дашборд',
    reports: 'Отчеты',
    'Cycle Materials': 'Циклические материалы',
    no: 'Нет',
    yes: 'Да',
    MINI: 'MINI',
    Executed: 'Размещено',
    'Not Executed': 'Не размещено',
    "Delivered to TMR": 'Получено ТМR',
    "Transit to TMR": 'В транзите до ТМR',
    "Delivered to City": 'Доставлено до города',
    "Delivered to city": 'Доставлено до города',
    "Transit to city": 'В транзите в город',
    "Not Delivered": 'Не доставлено',
    Delivered: 'Доставлено',
    'Not Delivered to CS': 'Не доставлено до центрального склада',
    'Delivered to CS': 'Доставлено до центрального склада',
    Target: 'Задача',
    total: 'всего',
    Total: 'Всего',
    Potential: 'Потенциал',
    'Central storage': 'Центральный склад',
    Stock: 'На складе',
    stock: 'На складе',
    transit: "В транзите",
    Transit: "В транзите",
    'In Stock': 'На складе',
    'No Stock': 'Нет на складе',
    '>90 D': '>90 D',
    Current: 'Current',
    '>90 d': '>90 d',
    '>1 Year': '>1 Year',
    '3-12 Month': '3-12 Month',
    '>1 year': '>1 year',
    New: 'New',
    Overdue: 'Overdue',
    Work: 'Work',
    Promo: 'Promo',
    Mol: 'Mol',
    TMR: 'TMR',
    'TYPE: Equipment': 'Type: Equipment',
    'TYPE: Parts': 'Type: Parts',
    'TYPE: Souvenirs': 'Type: Souvenirs',
    'TYPE: POSM': 'Type: POSM',

    export: {
        title: 'Выгрузка отчета',
        highlight: 'Подготовка займет некоторое время',
        text: 'Начать выгрузку в Excel?'
    },
    header: {
        logo: 'Дашборд по мерчендайзингу',
        menu: 'Меню',
        close: 'Закрыть',
    },
    login: {
        login: 'Авторизация',
        inputs: {
            login: 'Login',
            password: 'Password',
        },
        errors: {
            login: 'Поле Login обязательно к заполнению',
            password: 'Поле Password обязательно к заполнению',
        },
        submit: 'Авторизоваться',
    },
    ISF: 'Оборудование',
    Projects: 'Проекты',
    CycleMaterials: 'Циклические материалы',
    'Semi-permanent materials': 'Полу-постоянные материалы',
    'Equipment in PP': 'Премиум Партнеры',
    Headers: 'Лайтбоксы',
    posm: 'POSM Balance',
    'Delivery / Execution': 'Доставка / Размещение',
    Comparison: 'Сравнение',
    Progress: 'Прогресс',
    capexdepts: 'CAPEX depts',
    opexdepts: 'OPEX depts',
    Unit: 'Юнит',
    Region: 'Регион',
    City: 'Город',
    CM: 'CM',
    ClearFilter: 'Снять фильтр',
    Loading: 'Загрузка...',
    Type: 'Тип',
    TYPE: 'ТИП',
    MULTICATEGORY: 'Мультикатегорийное',
    Multicategory: 'Мультикатегорийное',
    OTHER: 'Другое',
    Other: 'Другое',
    ALL: 'Всего',
    'Must set': 'Обязательный комплект',
    'MUST SET': 'Обязательный комплект',
    'Must set total': 'Обязательный комплект',
    'Other total': 'Другое',
    'Total qty': 'Всего',
    Week: 'Неделя',
    Image: 'Изображение',
    'Execution status': 'Размещение',
    outlet: 'точки',
    equip: 'штуки',
    'Total BWD Multicategory': 'Всего BWD Мультикатегор.',
    'Total Vitrine / Taylor made': 'Всего Витрины / Тейлор мейд',
    'Total BWD + Vitrine': 'Всего BWD + Витрины',
    'Attention!': 'Внимание!',
    "The report is being prepared. Download the report by clicking on the icon": 'Отчет готовится. Скачать можно будет, нажав на кнопку',
    'Start typing or select...': 'Начните вводить или выберите...',
    'CYCLE': 'Выбор',
    'CYCLE_Selection': 'цикла',
    'ACTIVITY': 'Выбор',
    'ACTIVITY_Selection': 'активности',
    'Detailed summary': 'Детализация',
    leftSidebar: {
        dashboard: 'Dashboard',
        isf: 'ISF / POSM',
        semipermanentMaterials: 'Semi-permanent materials',
        equipment: 'Equipment',
        headers: 'Headers',
        posm: 'POSM Balance',
        lightbox: 'Lightbox',
        deliveryexecution: 'Delivery / Execution',
        cycleMaterials: "Cycle Materials",
        comparison: 'Comparison',
        progress: 'Progress',
        capexdepts: 'CAPEX depts',
        opexdepts: 'OPEX depts',
        stock: 'STOCK',
        transits: "TRANSITS",
        premiumPartners: "Premium Partners",
    },
}
