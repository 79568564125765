import axios from 'axios';
// import axiosLocalStorageAdapter from 'axios-localstorage-adapter';

// eslint-disable-next-line camelcase
import {read_cookie, delete_cookie} from 'sfcookies';
import _ from "lodash"

export function get(url, params = {}) {
    const token = read_cookie('access_token');

    if (token.length > 0) {
        params.headers = {'Authorization': `Bearer ${token}`};
    }

    return axios.get(import.meta.env.VITE_API_BASE_URL + url, {
        params,
        withCredentials: true
    })
        .then((response) => {
            if (localStorage.getItem('LoggedUser') !== 'false' && response.data.status === 403) {
                delete_cookie('PHPSESSID');
                localStorage.setItem('LoggedUser', false);
                window.location.reload();
            }
            return response;
        })
        .catch((error) => {
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        delete_cookie('PHPSESSID');
                        window.location.reload();
                        break;
                    default:
                        break;
                }
            }
            return error;
        });
}

export function post(url, data = {}, headers = {}) {
    headers = Object.assign(headers, {'Content-Type': 'multipart/form-data'});

    return axios.post(import.meta.env.VITE_API_BASE_URL + url, data, {
        headers,
        withCredentials: true
    }).then((response) => {
        if (localStorage.getItem('LoggedUser') !== 'false' && response.data.status === 403) {
            delete_cookie('PHPSESSID');
            localStorage.setItem('LoggedUser', false);
            window.location.reload();
        }
        return response;
    }).catch((error) => {
        if (error.response && error.response.status !== undefined) {
            switch (error.response.status) {
                case 401:
                    delete_cookie('access_token');
                    window.location.reload();
                    break;
                default:
                    break;
            }
        }
        return error;
    });
}

const sortUnit = {
    'Москва': 1,
    'Северо-Запад': 2,
    'Юг': 3,
    'Урал': 4,
    'Волга-Центр': 5,
    'Сибирь-ДВ': 6
};

export function placeholder(idx) {
    const p = {
        unit: 'Unit',
        region: 'Region',
        city: 'City',
        area: 'CM',
        territory: 'TMR'
    }
    return p[idx]
}

export function setFilter(multiselects, filters) {
    const values = {}
    multiselects.map(e => {
        const key = (e.placeholder) ? e.placeholder : e.property
        values[key] = e.value
    });

    multiselects = [];
    Object.keys(filters).map(idx => {
        let options = _.get(filters, idx);
        if (!Array.isArray(options)) {
            options = Object.values(options);
        }
        options = options.filter(option => option);
        if (Array.isArray(options) && options.length > 0) {
            const pl = placeholder(idx);
            if (pl === 'Unit') {
                const sortOptions = options.map(option => {
                    return {
                        name: option,
                        sort: sortUnit[option]
                    }
                })
                options = [];
                _.sortBy(sortOptions, ['sort']).map((option) => {
                    options.push(option.name);
                })
            }
            multiselects.push({
                value: !_.isEmpty(values[pl]) ? values[pl] : null,
                options: options,
                placeholder: pl
            })
        }
    });

    return multiselects;
}

export function prepareProgressResponse(records, total, name = 'name', targetName = 'Target') {
    let list = {};
    const totalData = {Executed: 0, Delivered: 0, NotDelivered: 0, Total: 0};
    records.map(record => {
        const plan_quantity = +record.plan_quantity;
        const fact_delivery = +record.fact_delivery;
        const fact_quantity = +record.fact_quantity;
        const notDelivered = 0;// plan_quantity - fact_delivery;

        totalData.Total += plan_quantity;
        totalData.Executed += fact_quantity;
        totalData.Delivered += fact_delivery;

        const key = record[name];
        if (_.isEmpty(list[key])) {
            list[key] = {
                label: key,
                executed: fact_quantity,
                delivered: fact_delivery,
                notDelivered: notDelivered < 0 ? 0 : notDelivered,
                target: plan_quantity,
                type: record.type,
            };
        } else {
            list[key].executed += fact_quantity;
            list[key].delivered += fact_delivery;
            list[key].notDelivered += notDelivered < 0 ? 0 : notDelivered;
            list[key].target += plan_quantity;
        }
    })

    list = Object.values(list).map(item => {
        item.stats = [
            {
                name: "Executed",
                value: item.executed,
            },
            {
                name: "Delivered to TMR",
                value: item.delivered,
            },
            {
                name: "Not Delivered",
                value: item.notDelivered,
            },
            {
                name: targetName,
                value: item.target,
            },
        ]
        return item
    })

    return {
        list,
        total: total.map(item => {
            switch (item.name) {
                case 'Target':
                case 'Potential':
                    item.value = totalData.Total;
                    break;
                case 'Executed':
                    item.value = totalData.Executed;
                    break;
                case 'Delivered':
                case 'Stock':
                    item.value = totalData.Delivered;
                    break;
                case 'Not Delivered':
                    console.log('-->>>Not Delivered', totalData.Total, totalData.Delivered)
                    item.value = totalData.Total - totalData.Delivered;
                    item.value = item.value < 0 ? 0 : item.value;
                    break;
            }
            return item;
        })
    }
}

export function prepareComparisonResponse(records, total, name = 'unit', targetName = 'Target') {
    let list = {};
    const totalData = {Executed: 0, Delivered: 0, NotDelivered: 0, Total: 0, DeliveryToTmr: 0, DeliveryToCity: 0};
    records.map(record => {
        const plan_quantity = +record.plan_quantity;
        const fact_delivery = +record.fact_delivery;
        const fact_quantity = +record.fact_quantity;

        let notDelivered = (plan_quantity - fact_delivery);
        if (targetName === 'Potential') {
            notDelivered = 0;
        }
        totalData.Total += plan_quantity;
        totalData.Executed += fact_quantity;
        totalData.Delivered += fact_delivery;

        const key = record[name];
        if (_.isEmpty(list[key])) {
            list[key] = {
                label: key,
                sort: sortUnit[key],
                executed: fact_quantity,
                delivered: fact_delivery,
                notDelivered: notDelivered < 0 ? 0 : notDelivered,
                target: plan_quantity,
                type: record.type,
            };
        } else {
            list[key].executed += fact_quantity;
            list[key].delivered += fact_delivery;
            list[key].notDelivered += notDelivered < 0 ? 0 : notDelivered;
            list[key].target += plan_quantity;
        }
        if (record.delivery_tmr_quantity) {
            totalData.DeliveryToTmr += +record.delivery_tmr_quantity
        }

        if (record.delivery_city_quantity) {
            totalData.DeliveryToCity += +record.delivery_city_quantity
        }
    })

    list = Object.values(list).map(item => {
        if (targetName === 'Potential') {
            item.stats = [
                {
                    name: "Executed",
                    value: item.executed,
                },
                {
                    name: "Stock",
                    value: item.target - item.executed,
                },
                {
                    name: targetName,
                    value: item.target,
                },
            ]
        } else {
            item.stats = [
                {
                    name: "Executed",
                    value: item.executed,
                },
                {
                    name: "Delivered",
                    value: item.delivered,
                },
                {
                    name: "Not Delivered",
                    value: item.notDelivered < 0 ? 0 : item.notDelivered,
                },
                {
                    name: targetName,
                    value: item.target,
                },
            ]
        }

        return item;
    })

    return {
        list: _.sortBy(list, ['sort']),
        total: total.map(item => {
            const notDelivered = totalData.Total - totalData.Delivered;
            switch (item.name) {
                case 'Target':
                case 'Potential':
                    item.value = totalData.Total;
                    break;
                case 'Executed':
                    item.value = totalData.Executed;
                    break;
                case 'Delivered':
                case 'Stock':
                    item.value = totalData.Delivered;
                    break;
                case 'Not Delivered':
                    item.value = notDelivered < 0 ? 0 : notDelivered;
                    break;
                case 'Delivered to TMR':
                    item.value = totalData.DeliveryToTmr;
                    break;
                case 'Delivered to City':
                    item.value = totalData.DeliveryToCity;

                    break;
            }
            return item;
        })
    }
}

export function prepareDeliveryExecutionResponse(records, total, name = 'marketing_name', targetName = 'Target') {
    let products = [];
    const totalData = {Executed: 0, Delivered: 0, NotDelivered: 0, Total: 0};
    records.map(record => {
        const plan_quantity = +record.plan_quantity;
        const fact_delivery = +record.fact_delivery;
        const fact_quantity = +record.fact_quantity;
        const notDelivered = record.type_opex === 'OPEX' ? 0 : plan_quantity - fact_delivery;

        totalData.Total += plan_quantity;
        totalData.Executed += fact_quantity;
        totalData.Delivered += fact_delivery;

        if (record.type_opex !== 'OPEX') {
            totalData.NotDelivered += notDelivered < 0 ? 0 : notDelivered;
        } else {
            totalData.NotDelivered += 0;
        }

        const key = record[name];
        if (+record.auto === 1) {
            totalData.NotDelivered = 0;
        }

        if (_.isEmpty(products[key])) {
            const types = [];
            if (record.type) {
                types.push(record.type);
            }
            if (record.type_opex) {
                types.push((record.type_opex === 'OPEX') ? 'OTHER' : 'MULTICATEGORY');
            }
            products[key] = {
                label: key,
                executed: (+record.auto === 1) ? plan_quantity : fact_quantity,
                name: record.name,
                photo: record.image,
                delivered: (+record.auto === 1) ? plan_quantity : fact_delivery,
                notDelivered: notDelivered < 0 ? 0 : notDelivered,
                target: plan_quantity,
                type: record.type,
                type_opex: record.type_opex,
                new: record.new,
                info: record.info,
                graph: record.graph,
                types: types
            };
        } else {
            if (+record.auto === 1) {
                products[key].executed += plan_quantity;
                products[key].delivered += plan_quantity;
                products[key].notDelivered += 0;
                products[key].target += plan_quantity;
            } else {
                products[key].executed += fact_quantity;
                products[key].delivered += fact_delivery;
                if (record.type_opex !== 'OPEX') {
                    products[key].notDelivered += notDelivered < 0 ? 0 : notDelivered;
                } else {
                    products[key].notDelivered += 0;
                }

                products[key].target += plan_quantity;
            }
        }
        if (record.type_opex === 'OPEX') {
            products[key].notDelivered = 0;
        }
    })

    products = Object.values(products).map(item => {
        if (targetName === 'Potential') {
            item.stats = [
                {
                    name: "Executed",
                    value: item.executed,
                },
                {
                    name: "Stock",
                    value: item.target - item.executed,
                },
                {
                    name: targetName,
                    value: item.target,
                },
            ]
        } else {
            item.stats = [
                {
                    name: "Executed",
                    value: item.executed,
                },
                {
                    name: "Delivered",
                    value: item.delivered,
                },
                {
                    name: "Not Delivered",
                    value: item.notDelivered,
                },
                {
                    name: targetName,
                    value: item.target,
                },
            ]
        }
        return item;
    })

    return {
        products: _.sortBy(products, ['type_opex', 'sort']),
        total: total.map(item => {
            switch (item.name) {
                case 'Target':
                case 'Potential':
                    item.value = totalData.Total;
                    break;
                case 'Executed':
                    item.value = totalData.Executed;
                    break;
                case 'Stock':
                    item.value = totalData.Total - totalData.Executed;
                    break;
                case 'Delivered':
                    item.value = totalData.Delivered;
                    break;
                case 'Not Delivered':
                    item.value = totalData.Total - totalData.Delivered;
                    item.value = item.value < 0 ? 0 : item.value;
                    break;
            }
            return item;
        })
    }
}

export function prepareCycleProgressResponse(records, total, name = 'name') {
    let list = {};
    const totalData = {Executed: 0, DeliveredToCity: 0, TransitToTMR: 0, DeliveredTmr: 0, NotDelivered: 0, Total: 0};
    records.map(record => {
        const plan_quantity = +record.plan_quantity;
        const delivery_city_quantity = +record.delivery_city_quantity;
        const delivery_tmr_quantity = +record.delivery_tmr_quantity;
        const transit_tmr_quantity = +record.transit_tmr_quantity;
        const fact_quantity = +record.fact_quantity;
        const notDelivered = +record.not_delivery; //(plan_quantity - delivery_city_quantity);

        totalData.Total += plan_quantity;
        totalData.Executed += fact_quantity;
        totalData.DeliveredToCity += delivery_city_quantity;
        totalData.DeliveredTmr += delivery_tmr_quantity;
        totalData.TransitToTMR += transit_tmr_quantity;
        totalData.NotDelivered += notDelivered < 0 ? 0 : notDelivered;
        const key = record[name];
        if (_.isEmpty(list[key])) {
            list[key] = {
                label: record[name],
                sort: sortUnit[key],
                executed: fact_quantity,
                deliveredToTMR: delivery_tmr_quantity,
                transitToTMR: transit_tmr_quantity,
                deliveredToCity: delivery_city_quantity,
                notDelivered: notDelivered < 0 ? 0 : notDelivered,
                target: plan_quantity,
                type: record.type,
                new: record.new,
            };
        } else {
            list[key].executed += fact_quantity;
            list[key].deliveredToTMR += delivery_tmr_quantity;
            list[key].transitToTMR += transit_tmr_quantity;
            list[key].deliveredToCity += delivery_city_quantity;
            list[key].notDelivered += notDelivered < 0 ? 0 : notDelivered;
            list[key].target += plan_quantity;
        }
    })

    list = Object.values(list).map(item => {
        item.stats = [
            {
                name: "Executed",
                value: item.executed,
            },
            {
                name: "Delivered to TMR",
                value: item.deliveredToTMR,
            },
            {
                name: "Transit to TMR",
                value: item.transitToTMR,
            },
            {
                name: "Delivered to City",
                value: item.deliveredToCity,
            },
            {
                name: "Not Delivered",
                value: item.notDelivered,
            },
            {
                name: "Target",
                value: item.target,
            },
        ]
        return item;
    })

    return {
        list: _.sortBy(list, ['sort']),
        total: total.map(item => {
            switch (item.name) {
                case 'Target':
                    item.value = totalData.Total;
                    break;
                case 'Executed':
                    item.value = totalData.Executed;
                    break;
                case 'Delivered to TMR':
                    item.value = totalData.DeliveredTmr;
                    break;
                case 'Transit to TMR':
                    item.value = totalData.TransitToTMR;
                    break;
                case 'Delivered to City':
                    item.value = totalData.DeliveredToCity;
                    break;
                case 'Not Delivered':
                    item.value = totalData.NotDelivered < 0 ? 0 : totalData.NotDelivered;
                    break;
            }
            return item;
        })
    }
}

export function objectToQueryString(obj) {
    const results = [];
    _.forOwn(obj, (value, key) => {
        if (Array.isArray(value)) {
            _.forOwn(value, value => {
                if (value !== undefined)
                    results.push(`filters[${key}][]=${value}`);
            });
        } else {
            if (value !== undefined)
                results.push(`filters[${key}]=${value}`);
        }
    });
    return results.join('&');
}
