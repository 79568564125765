import {createStore} from 'vuex'
import {get, post} from "../service";
import router from '../router'
import _ from "lodash"

const store = createStore({
    state: {
        user: null,
        isfDelivery: null,
        isfComparison: null,
        isfProgress: null,
        loading: false,
        isfFilter: [],
        setIsfFilters: [],
        isfWeeks: [],
        isfProgressViewType: 'multicategory',//graph - multicategory
        isfProgressMulticategoryData: [],
        currentIsf: false,

        headersDelivery: null,
        headersComparison: null,
        headersProgress: null,
        headersFilter: [],
        setHeadersFilters: [],
        currentHeader: false,

        touchpointFilter: [],
        setTouchpointFilter: [],
        touchpointDeliveryExecution: null,
        touchpointComparison: null,
        touchpointsProgress: null,

        projectsFilter: [],
        setProjectsFilter: [],
        projectsDeliveryExecution: null,
        projectsComparison: null,
        projectsProgress: null,

        cycleFilter: [],
        setCycleFilter: [],
        cycleDeliveryExecution: null,
        cycleComparison: null,
        cycleProgress: null,

        prrpDeliveryExecution: null,
        prrpDeliveryExecutionTotal: null,
        prrpFilter: [],
        setPrrpFilter: [],

        globalFilters: [],
        weeks: [],

        downloader: true,
        amount_requested: 0, // Кол-во запросов на репорты
        amount_ready: 0, // Репорты готовые к скачиванию
        amount_new: 0, // Кол-во новых репортов, готовых к скачиванию
        reports: [],

        SalesChannelFilter_Options: ['Indep/Local', 'RKA'],
        SalesChannelFilter_Value: ['Indep/Local', 'RKA'],

        CMFilterOptions: ['ALL', 'MUST SET', 'OTHER'],
        CMFilterValue: ['ALL'],

        alert: {id: 0, title: '', message: ''},

        ISFFilterOptions: ['ALL', 'BWD', 'OHD'],
        ISFFilterValue: 'ALL',
        ISFFilterVuseGlo: ['GLO Devices', 'Vuse', 'FMC', 'ALL'],
        ISFFilterVuseGloValue: ['ALL'],
        // CAPEX OPEX
        PEXFilterOptions: ['ALL', 'MULTICATEGORY', 'OTHER'],
        PEXFilterValue: 'ALL',
        // Indep/Local RKA
        RKAFilterOptions: ['Indep/Local', 'RKA'],
        RKAFilterValue: ['Indep/Local'],
        TouchpointRKAFilterValue: ['Indep/Local', 'RKA'],
        HeadersRKAFilterValue: ['Indep/Local', 'RKA'],
        HeadersFilterVuseGlo: ['GLO Devices', 'Vuse', 'FMC', 'ALL'],
        HeadersFilterVuseGloValue: ['ALL'],
        activityFilter: [],
    },
    getters: {
        getUser: () => store.state.user,
        loading: () => store.state.loading,
        isfDeliveryExecution: () => store.state.isfDelivery,
        isfComparison: () => store.state.isfComparison,
        isfProgress: () => store.state.isfProgress,
        getSetIsfFilters: () => store.state.setIsfFilters,
        getIsfFilter: () => store.state.isfFilter,
        getIsfWeeks: () => store.state.isfWeeks,
        getIsfProgressViewType: () => store.state.isfProgressViewType,
        getIsfProgressMulticategoryData: () => store.state.isfProgressMulticategoryData,
        getIsfFilterVuseGlo: () => store.state.ISFFilterVuseGlo,
        getISFFilterVuseGloValue: () => store.state.ISFFilterVuseGloValue,
        getCurrentIsf: () => store.state.currentIsf,

        getSetHeadersFilters: () => store.state.setHeadersFilters,
        getHeadersFilter: () => store.state.headersFilter,
        headersDeliveryExecution: () => store.state.headersDelivery,
        headersComparison: () => store.state.headersComparison,
        headersProgress: () => store.state.headersProgress,
        getHeadersFilterVuseGlo: () => store.state.HeadersFilterVuseGlo,
        getHeadersFilterVuseGloValue: () => store.state.HeadersFilterVuseGloValue,

        getTouchpointFilter: () => store.state.touchpointFilter,
        getSetTouchpointFilter: () => store.state.setTouchpointFilter,
        touchpointDeliveryExecution: () => store.state.touchpointDeliveryExecution,
        touchpointComparison: () => store.state.touchpointsComparison,
        touchpointsProgress: () => store.state.touchpointsProgress,

        getProjectsFilter: () => store.state.projectsFilter,
        getSetProjecstFilter: () => store.state.setProjectsFilter,
        projectsDeliveryExecution: () => store.state.projectsDeliveryExecution,
        projectsComparison: () => store.state.projectsComparison,
        projectsProgress: () => store.state.projectsProgress,

        getCycleFilter: () => store.state.cycleFilter,
        getSetCycleFilter: () => store.state.setCycleFilter,
        cycleDeliveryExecution: () => store.state.cycleDeliveryExecution,
        cycleComparison: () => store.state.cycleComparison,
        cycleProgress: () => store.state.cycleProgress,

        prrpDeliveryExecution: () => store.state.prrpDeliveryExecution,
        prrpDeliveryExecutionTotal: () => store.state.prrpDeliveryExecutionTotal,
        getPrrpFilter: () => store.state.prrpFilter,
        getSetPrrpFilter: () => store.state.setPrrpFilter,


        globalFilters: () => store.state.globalFilters,
        getWeeks: () => store.state.weeks,

        downloader: () => store.state.downloader,
        amount_requested: () => store.state.amount_requested, // Кол-во запросов на репорты
        amount_ready: () => store.state.amount_ready, // Репорты готовые к скачиванию
        amount_new: () => store.state.amount_new, // Кол-во новых репортов, готовых к скачиванию
        reports: () => store.state.reports,

        getSalesChannelFilterOptions: () => store.state.SalesChannelFilter_Options,
        getSalesChannelFilterValue: () => store.state.SalesChannelFilter_Value,

        getCMFilterOptions: () => store.state.CMFilterOptions,
        getCMFilterValue: () => store.state.CMFilterValue,

        getAlert: () => store.state.alert,

        getISFFilterOptions: () => store.state.ISFFilterOptions,
        getISFFilterValue: () => store.state.ISFFilterValue,

        getPEXFilterOptions: () => store.state.PEXFilterOptions,
        getPEXFilterValue: () => store.state.PEXFilterValue,

        getRKAFilterOptions: () => store.state.RKAFilterOptions,
        getRKAFilterValue: () => store.state.RKAFilterValue,

        getTouchpointRKAFilterValue: () => store.state.TouchpointRKAFilterValue,
        getHeadersRKAFilterValue: () => store.state.HeadersRKAFilterValue,

        getActivityFilter: () => store.state.activityFilter,
        currentHeader: () => store.state.currentHeader,
    },
    mutations: {
        updateAmountRepost: (state, record) => {
            state.amount_requested = +record.amount_requested;
            state.amount_ready = +record.amount_ready;
            state.amount_new = +record.amount_new;

            if (record.reports) {
                state.reports = record.reports;
            }
            if (record.alert) {
                state.alert = record.alert;
            }
        },
        setUser: (state, user) => {
            state.user = user
        },
        setIsfDeliveryExecution: (state, records) => {
            state.isfDelivery = records;
        },
        setIsfComparison: (state, records) => {
            state.isfComparison = records;
        },
        setIsfProgress: (state, records) => {
            state.isfProgress = records;
        },
        setSetIsfFilters: (state, records) => {
            state.setIsfFilters = records;
        },
        setIsfFilter: (state, filter) => {
            if (filter.property === 'isf_name') {
                const isfRecords = _.get(state.globalFilters, 'ISF')
                const currentRecord = _.find(isfRecords, {id: _.first(filter.value)});
                if (currentRecord) {
                    state.currentIsf = (currentRecord) ? currentRecord.current : false;
                }
            }

            const idx = _.findIndex(state.isfFilter, {property: filter.property});
            if (idx < 0) {
                state.isfFilter.push(filter);
            } else {
                state.isfFilter = state.isfFilter.map(item => item.property === filter.property ? filter : item)
            }
        },
        clearIsfFilter: (state, filter) => {
            state.ISFFilterVuseGloValue = [];
            state.isfFilter = state.isfFilter.map(filter => {
                switch (filter.property) {
                    case 'isf_name':
                        break;
                    case 'type':
                    case 'opex':
                        filter.value = 'ALL';
                        break;
                    case 'rka':
                        filter.value = ['Indep/Local', 'RKA'];
                        break;
                    default:
                        return false;
                }
                return filter;
            }).filter((item) => item);

            // console.log('-->>state.isfFilter', state.isfFilter)
        },
        setIsfProgressViewType: (state, type) => {
            state.isfProgressViewType = type;
        },
        setISFFilterVuseGloValue: (state, value) => {
            state.ISFFilterVuseGloValue = value;
        },
        setHeadersFilterVuseGloValue: (state, value) => {
            state.HeadersFilterVuseGloValue = value;
        },
        setHeadersDeliveryExecution: (state, records) => {
            state.headersDelivery = records;
        },
        setHeadersComparison: (state, records) => {
            state.headersComparison = records;
        },
        setHeadersProgress: (state, records) => {
            state.headersProgress = records;
        },
        setSetHeadersFilters: (state, records) => {
            state.setHeadersFilters = records;
        },
        setHeadersFilter: (state, filter) => {
            const idx = _.findIndex(state.headersFilter, {property: filter.property});
            if (filter.property === 'isf_name') {
                const isfRecords = _.get(state.globalFilters, 'Headers')
                const currentRecord = _.find(isfRecords, {id: _.first(filter.value)});
                if (currentRecord) {
                    state.currentHeader = (currentRecord) ? currentRecord.current : false;
                }
            }

            if (idx < 0) {
                state.headersFilter.push(filter);
            } else {
                state.headersFilter = state.headersFilter.map(item => item.property === filter.property ? filter : item)
            }
        },
        clearHeadersFilter: (state, filter) => {
            state.isfFilter = state.isfFilter.filter(filter => {
                return filter.property === 'isf_name'
            })
        },
        loading: (state, payload) => state.loading = payload,
        setTouchpointFilter: (state, filter) => {
            const idx = _.findIndex(state.touchpointFilter, {property: filter.property});
            if (idx < 0) {
                state.touchpointFilter.push(filter);
            } else {
                state.touchpointFilter = state.touchpointFilter.map(item => item.property === filter.property ? filter : item)
            }
        },

        setSetTouchpointFilter: (state, records) => {
            state.setTouchpointFilter = records;
        },
        setTouchpointDeliveryExecution: (state, records) => {
            state.touchpointDeliveryExecution = records;
        },
        clearTouchpointFilter: (state, filter) => state.touchpointFilter = [],
        setTouchpointsComparison: (state, records) => {
            state.touchpointsComparison = records;
        },
        setTouchpointsProgress: (state, records) => {
            state.touchpointsProgress = records;
        },
        setProjectsFilter: (state, filter) => {
            const idx = _.findIndex(state.projectsFilter, {property: filter.property});
            if (idx < 0) {
                state.projectsFilter.push(filter);
            } else {
                state.projectsFilter = state.projectsFilter.map(item => item.property === filter.property ? filter : item)
            }
        },
        setSetProjectsFilter: (state, records) => {
            state.setProjectsFilter = records;
        },
        setProjectsDeliveryExecution: (state, records) => {
            state.projectsDeliveryExecution = records;
        },
        clearProjectsFilter: (state, filter) => state.projectsFilter = [],
        setProjectsComparison: (state, records) => {
            state.projectsComparison = records;
        },
        setProjectsProgress: (state, records) => {
            state.projectsProgress = records;
        },

        setCycleFilter: (state, filter) => {
            const idx = _.findIndex(state.cycleFilter, {property: filter.property});
            if (idx < 0) {
                state.cycleFilter.push(filter);
            } else {
                state.cycleFilter = state.cycleFilter.map(item => item.property === filter.property ? filter : item)
            }
        },
        setSetCycleFilter: (state, records) => {
            state.setCycleFilter = records;
        },
        setCycleDeliveryExecution: (state, records) => {
            state.cycleDeliveryExecution = records;
        },
        clearCycleFilter: (state, filter) => {
            state.activityFilter = [];
            state.cycleFilter = state.cycleFilter.filter(filter => {
                return filter.property === 'cycle' || filter.property === 'name'
            })
        },
        setCycleComparison: (state, records) => {
            state.cycleComparison = records;
        },
        setCycleProgress: (state, records) => {
            state.cycleProgress = records;
        },

        setPrrpDeliveryExecution: (state, records) => {
            state.prrpDeliveryExecution = records;
        },
        setPrrpDeliveryExecutionTotal: (state, records) => {
            state.prrpDeliveryExecutionTotal = records;
        },
        setSetPrrpFilter: (state, records) => {
            state.setPrrpFilter = records;
        },
        setPrrpFilter: (state, filter) => {
            const idx = _.findIndex(state.prrpFilter, {property: filter.property});
            if (!_.isArray(state.prrpFilter)) {
                state.prrpFilter = [];
            }
            if (idx < 0) {
                state.prrpFilter.push(filter);
            } else {
                state.prrpFilter = state.prrpFilter.map(item => item.property === filter.property ? filter : item)
            }
        },
        clearPrrpFilter: (state, records) => {
            state.prrpFilter = [];
        },
        setEquipmentFilter: (state, records) => {
            state.prrpFilter = records;
        },

        setGlobalFilters: (state, records) => {
            state.globalFilters = records;
        },
        setWeeks: (state, records) => {
            state.weeks = records;
        },
        setIsfProductWeeks: (state, records) => {
            state.isfWeeks = records;
        },
        setSalesChannelFilterValue: (state, value) => {
            state.SalesChannelFilter_Value = value;
        },
        setISFFilterValue: (state, value) => {
            state.ISFFilterValue = value;
        },
        setPEXFilterValue: (state, value) => {
            state.PEXFilterValue = value;
        },
        setRKAFilterValue: (state, value) => {
            state.RKAFilterValue = value;
        },
        setTouchpointRKAFilterValue: (state, value) => {
            state.TouchpointRKAFilterValue = value;
        },
        setHeadersRKAFilterValue: (state, value) => {
            state.HeadersRKAFilterValue = value;
        },
        setActivityFilter: (state, value) => {
            state.activityFilter = value;
        }
    },
    actions: {
        initUser(context, payload) {
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'account/read-identity' : 'user/identity'
            context.commit('loading', true);
            return post(url).then(response => {
                if (response.data.success) {
                    localStorage.setItem('LoggedUser', true);
                    context.commit('setUser', {
                        user: response.data.records
                    });
                    // router.push({name: 'Dashboard'})
                } else {
                    localStorage.setItem('LoggedUser', false);
                    router.push({name: 'login'})
                }
                context.commit('loading', false);
                return response;
            })
        },
        setUser(context, payload) {
            context.commit('setUser', payload.user)
        },
        loadIsfDeliveryExecution(context, payload) {
            context.commit('loading', true);
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/isf-delivery-execution' : 'dashboard/isf-delivery-execution'
            return get(url, {filters: store.state.isfFilter}).then(response => {
                context.commit('setIsfDeliveryExecution', response.data.records)
                context.commit('setSetIsfFilters', response.data.filters)
                context.commit('loading', false);
                return response;
            })
        },
        loadIsfComparison(context, payload) {
            context.commit('loading', true);
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/isf-comparison' : 'dashboard/isf-comparison'
            return get(url, {filters: store.state.isfFilter}).then(response => {
                context.commit('setIsfComparison', response.data.records)
                context.commit('setSetIsfFilters', response.data.filters)
                context.commit('loading', false);
                return response;
            })
        },
        loadIsfProgress(context, payload) {
            context.commit('loading', true);
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/isf-progress' : 'dashboard/isf-progress';
            return get(url, {
                filters: store.state.isfFilter,
                type: store.state.isfProgressViewType
            }).then(response => {
                context.commit('setIsfProgress', response.data.records)
                context.commit('setSetIsfFilters', response.data.filters)
                context.commit('loading', false);
                return response;
            })
        },
        setIsfFilter(context, payload) {
            context.commit('setIsfFilter', payload)
        },
        clearIsfFilter(context, payload) {
            context.commit('clearIsfFilter', payload);
            context.commit('setISFFilterValue', 'ALL');
            context.commit('setPEXFilterValue', 'ALL');
            context.commit('setRKAFilterValue', ['Indep/Local', 'RKA']);
        },
        setIsfProgressViewType(context, payload) {
            context.commit('setIsfProgressViewType', payload);
        },
        setISFFilterVuseGloValue(context, payload) {
            context.commit('setISFFilterVuseGloValue', payload);
        },
        setHeadersFilterVuseGloValue(context, payload) {
            context.commit('setHeadersFilterVuseGloValue', payload);
        },
        loadHeadersDeliveryExecution(context, payload) {
            context.commit('loading', true);
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/headers-delivery-execution' : 'dashboard/headers-delivery-execution';
            return get(url, {filters: store.state.headersFilter}).then(response => {
                context.commit('setHeadersDeliveryExecution', response.data.records)
                context.commit('setSetHeadersFilters', response.data.filters)
                context.commit('loading', false);
                return response;
            })
        },
        loadHeadersComparison(context, payload) {
            context.commit('loading', true);
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/headers-comparison' : 'dashboard/headers-comparison';
            return get(url, {filters: store.state.headersFilter}).then(response => {
                context.commit('setHeadersComparison', response.data.records)
                context.commit('setSetHeadersFilters', response.data.filters)
                context.commit('loading', false);
                return response;
            })
        },
        loadHeadersProgress(context, payload) {
            context.commit('loading', true);
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/headers-progress' : 'dashboard/headers-progress';
            return get(url, {filters: store.state.headersFilter}).then(response => {
                context.commit('setHeadersProgress', response.data.records)
                context.commit('setSetHeadersFilters', response.data.filters)
                context.commit('loading', false);
                return response;
            })
        },
        setHeadersFilter(context, payload) {
            context.commit('setHeadersFilter', payload)
        },
        clearHeadersFilter(context, payload) {
            context.commit('clearHeadersFilter', payload)
        },


        setTouchpointFilter(context, payload) {
            context.commit('setTouchpointFilter', payload)
        },
        clearTouchpointFilter(context, payload) {
            context.commit('clearTouchpointFilter', payload)
        },
        loadTouchpointDeliveryExecution(context, payload) {
            context.commit('loading', true);
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/touchpoint-delivery-execution' : 'dashboard/touchpoint-delivery-execution';
            return get(url, {filters: store.state.touchpointFilter}).then(response => {
                context.commit('setTouchpointDeliveryExecution', response.data.records)
                context.commit('setSetTouchpointFilter', response.data.filters)
                context.commit('loading', false);
                return response;
            })
        },
        loadTouchpointComparison(context, payload) {
            context.commit('loading', true);
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/touchpoint-comparison' : 'dashboard/touchpoint-comparison';
            return get(url, {filters: store.state.touchpointFilter}).then(response => {
                context.commit('setTouchpointsComparison', response.data.records)
                context.commit('setSetTouchpointFilter', response.data.filters)
                context.commit('loading', false);
                return response;
            })
        },
        loadTouchpointProgress(context, payload) {
            context.commit('loading', true);
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/touchpoint-progress' : 'dashboard/touchpoint-progress';
            return get(url, {filters: store.state.touchpointFilter}).then(response => {
                context.commit('setTouchpointsProgress', response.data.records)
                context.commit('setSetTouchpointFilter', response.data.filters)
                context.commit('loading', false);
                return response;
            })
        },

        setProjectsFilter(context, payload) {
            context.commit('setProjectsFilter', payload)
        },
        loadProjectsDeliveryExecution(context, payload) {
            context.commit('loading', true);
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/projects-delivery-execution' : 'dashboard/projects-delivery-execution';
            return get(url, {filters: store.state.projectsFilter}).then(response => {
                context.commit('setProjectsDeliveryExecution', response.data.records)
                context.commit('setSetProjectsFilter', response.data.filters)
                context.commit('loading', false);
                return response;
            })
        },
        loadProjectsComparison(context, payload) {
            context.commit('loading', true);
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/projects-comparison' : 'dashboard/projects-comparison';
            return get(url, {filters: store.state.projectsFilter}).then(response => {
                context.commit('setProjectsComparison', response.data.records)
                context.commit('setSetProjectsFilter', response.data.filters)
                context.commit('loading', false);
                return response;
            })
        },
        loadProjectsProgress(context, payload) {
            context.commit('loading', true);
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/projects-progress' : 'dashboard/projects-progress';
            return get(url, {filters: store.state.projectsFilter}).then(response => {
                context.commit('setProjectsProgress', response.data.records)
                context.commit('setSetProjectsFilter', response.data.filters)
                context.commit('loading', false);
                return response;
            })
        },
        clearProjectsFilter(context) {
            context.commit('clearProjectsFilter')
        },

        setCycleFilter(context, payload) {
            context.commit('setCycleFilter', payload)
        },
        loadCycleDeliveryExecution(context, payload) {
            context.commit('loading', true);
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/cycle-delivery-execution' : 'dashboard/cycle-delivery-execution';
            return get(url, {filters: store.state.cycleFilter}).then(response => {
                if (response.data) {
                    context.commit('setCycleDeliveryExecution', response.data.records)
                    context.commit('setSetCycleFilter', response.data.filters)
                }
                context.commit('loading', false);
                return response;
            })
        },
        loadCycleComparison(context, payload) {
            context.commit('loading', true);
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/cycle-comparison' : 'dashboard/cycle-comparison';
            return get(url, {filters: store.state.cycleFilter}).then(response => {
                context.commit('setCycleComparison', response.data.records)
                context.commit('setSetCycleFilter', response.data.filters)
                context.commit('loading', false);
                return response;
            })
        },
        loadCycleProgress(context, payload) {
            context.commit('loading', true);
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/cycle-progress' : 'dashboard/cycle-progress';
            return get(url, {filters: store.state.cycleFilter}).then(response => {
                context.commit('setCycleProgress', response.data.records)
                context.commit('setSetCycleFilter', response.data.filters)
                context.commit('loading', false);
                return response;
            })
        },
        clearCycleFilter(context) {
            context.commit('clearCycleFilter')
        },
        setGlobalFilters(context, payload) {
            context.commit('setGlobalFilters', payload)
        },
        loadCycleWeeks(context, payload) {
            context.commit('loading', true);
            payload.filters = store.state.cycleFilter;
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/get-plan-week' : 'dashboard/get-plan-week';
            return get(url, payload).then(response => {
                context.commit('loading', false);
                context.commit('setWeeks', response.data.data.cycleMaterialsData)
                return response;
            })
        },
        loadIsfProductWeeks(context, payload) {
            context.commit('loading', true);
            payload.filters = store.state.isfFilter;
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/get-isf-week' : 'dashboard/get-isf-week';
            return get(url, payload).then(response => {
                context.commit('loading', false);
                context.commit('setIsfProductWeeks', response.data.data.cycleMaterialsData)
                return response;
            })
        },
        getDeliveryExecutionReport(context, payload) {
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/export-delivery-execution' : 'dashboard/export-delivery-execution';
            return get(url, payload).then(response => {
                context.commit('loading', false);

                return response;
            })
        },
        getHeadersDeliveryExecutionReport(context, payload) {
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/export-headers-delivery-execution' : 'dashboard/export-headers-delivery-execution';
            return get(url, payload).then(response => {
                context.commit('loading', false);

                return response;
            })
        },
        getCycleMaterialsReport(context, payload) {
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/export-cycle-materials' : 'dashboard/export-cycle-materials';
            return get(url, payload).then(response => {
                context.commit('loading', false);

                return response;
            })
        },
        getTouchpointsReport(context, payload) {
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/semi-permanent-export' : 'dashboard/semi-permanent-export';
            return get(url, payload).then(response => {
                context.commit('loading', false);

                return response;
            })
        },
        getPrrpReport(context, payload) {
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/prrp-export' : 'dashboard/prrp-export';
            return get(url, payload).then(response => {
                context.commit('loading', false);

                return response;
            })
        },
        setReadReport(context, payload) {
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/set-read-report' : 'dashboard/set-read-report';
            return post(url, {
                'ids[]': payload
            }).then(response => {
                context.commit('loading', false);

                return response;
            })
        },
        updateNotification(context, payload) {
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/get-count-report' : 'dashboard/get-count-report';
            return get(url, payload).then(response => {
                if (response.data.success) {
                    context.commit('updateAmountRepost', response.data)
                }

                return response;
            })
        },
        initNotification(context, payload) {
            const update = () => {
                const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/get-count-report' : 'dashboard/get-count-report';
                return get(url, payload).then(response => {
                    if (response.data.success) {
                        context.commit('updateAmountRepost', response.data)
                    }

                    return response;
                })
            }
            update();
            setInterval(() => {
                update();
            }, 60000)
        },
        setSalesChannelFilterValue(context, value) {
            context.commit('setSalesChannelFilterValue', value)
        },
        hideAlarm(context, value) {
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/alerts/mark-read' : 'dashboard/alerts/mark-read';
            post(url, value).then(response => {
                console.log(response)
            })
        },
        setISFFilterValue(context, value) {
            context.commit('setISFFilterValue', value)
        },
        setPEXFilterValue(context, value) {
            context.commit('setPEXFilterValue', value)
        },
        setRKAFilterValue(context, value) {
            context.commit('setRKAFilterValue', value)
        },
        setTouchpointRKAFilterValue(context, value) {
            context.commit('setTouchpointRKAFilterValue', value)
        },
        setHeadersRKAFilterValue(context, value) {
            context.commit('setHeadersRKAFilterValue', value)
        },
        setActivityFilter(context, value) {
            context.commit('setActivityFilter', value)
        },
        loadPrrpDeliveryExecution(context, payload) {
            context.commit('loading', true);
            const url = (+import.meta.env.VITE_API_API_VER === 1) ? 'dashboard/api/prrp-delivery-execution' : 'dashboard/prrp-delivery-execution';

            return get(url, {filters: store.state.prrpFilter}).then(response => {
                if (response.data) {
                    context.commit('setPrrpDeliveryExecution', response.data.records);
                    context.commit('setPrrpDeliveryExecutionTotal', response.data.total);
                    context.commit('setSetPrrpFilter', response.data.filters);
                }
                context.commit('loading', false);
                return response;
            })
        },
        setEquipmentFilter(context, value) {
            context.commit('setEquipmentFilter', value)
        },
        clearPrrpFilter(context) {
            context.commit('clearPrrpFilter')
        },
        setPrrpFilter(context, payload) {
            context.commit('setPrrpFilter', payload)
        },
    }
})
export default store
