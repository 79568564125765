<script>
import Dropdown from './Dropdown.vue';
export default {
  props: ['multiselects', 'section', 'disableAllClear'],
  data() {
    return {
      locale: localStorage.getItem('locale')
    };
  },
  components: {
    Dropdown
  },
  methods: {
    clearFilter: function (section) {
      for (var i = 0; this.multiselects.length > i; i++) {
        this.multiselects[i].value = null;
      }
      switch (section) {
        case 'isf':
          this.$store.dispatch('clearIsfFilter');
          // this.updateIsf();
          break;
        case 'touchpoints':
          this.$store.dispatch('clearTouchpointFilter');
          this.updateTouchponts();
          break;
        case 'projects':
          this.$store.dispatch('clearProjectsFilter');
          this.updateProjects();
          break;
        case 'cycle':
          const update = (this.$store.getters.getActivityFilter.length === 0)
          this.$store.dispatch('clearCycleFilter');
          if (update) {
            this.updateCycle();
          }
          break;
        case 'headers':
          this.$store.dispatch('clearHeadersFilter');
          this.updateHeaders();
            break;
        case 'prrp':
          this.$store.dispatch('clearPrrpFilter');
          this.updatePrrp();
          break;
      }
    },
    updateFilter: function (value, property) {
      const section = this.section;
      console.log('--->>section', section)
      switch (section) {
        case 'isf':
          this.$store.dispatch('setIsfFilter', {property, value});
          this.updateIsf();
          break;
        case 'touchpoints':
          this.$store.dispatch('setTouchpointFilter', {property, value});
          this.updateTouchponts();
          break;
        case 'projects':
          this.$store.dispatch('setProjectsFilter', {property, value});
          this.updateProjects();
          break;
        case 'cycle':
          this.$store.dispatch('setCycleFilter', {property, value});
          this.updateCycle();
          break;
        case 'headers':
          this.$store.dispatch('setHeadersFilter', {property, value});
          this.updateHeaders();
          break;
        case 'prrp':
          this.$store.dispatch('setPrrpFilter', {property, value});
          this.updatePrrp();
          break;
      }
    },
    clearFilters: function (value, property, section) {
      switch (section) {
        case 'isf':
          this.$store.dispatch('setIsfFilter', {property, value:''});
          this.updateIsf();
          break;
        case 'touchpoints':
          this.$store.dispatch('setTouchpointFilter', {property, value:''});
          this.updateTouchponts();
          break;
        case 'projects':
          this.$store.dispatch('setProjectsFilter', {property, value:''});
          this.updateProjects();
          break;
        case 'cycle':
          this.$store.dispatch('setCycleFilter', {property, value:''});
          this.updateCycle();
          break;
        case 'headers':
          this.$store.dispatch('setHeadersFilter', {property, value:''});
          this.updateHeaders();
          break;
        case 'prrp':
          this.$store.dispatch('setPrrpFilter', {property, value: ''});
          this.updatePrrp();
          break;
      }
    },
    updateIsf: function(){
      switch (this.$route.path) {
        case '/panel/ISF/Equipment/Delivery-Execution':
          this.$store.dispatch('loadIsfDeliveryExecution');
          break;
        case '/panel/ISF/Equipment/Comparison':
          this.$store.dispatch('loadIsfComparison');
          break;
        case '/panel/ISF/Equipment/Progress':
          this.$store.dispatch('loadIsfProgress');
          break;
      }
    },
    updateHeaders: function(){
      switch (this.$route.path) {
        case '/panel/ISF/Headers/Delivery-Execution':
          this.$store.dispatch('loadHeadersDeliveryExecution');
          break;
        case '/panel/ISF/Headers/Comparison':
          this.$store.dispatch('loadHeadersComparison');
          break;
        case '/panel/ISF/Headers/Progress':
          this.$store.dispatch('loadHeadersProgress');
          break;
      }
    },
    updateTouchponts: function(){
      switch (this.$route.path) {
        case '/panel/Touchpoints/Delivery-Execution':
          this.$store.dispatch('loadTouchpointDeliveryExecution');
          break;
        case '/panel/Touchpoints/Comparison':
          this.$store.dispatch('loadTouchpointComparison');
          break;
        case '/panel/Touchpoints/Progress':
          this.$store.dispatch('loadTouchpointProgress');
          break;
      }
    },
    updateProjects: function () {
      switch (this.$route.path) {
        case '/panel/Projects/Delivery-Execution':
          this.$store.dispatch('loadProjectsDeliveryExecution');
          break;
        case '/panel/Projects/Comparison':
          this.$store.dispatch('loadProjectsComparison');
          break;
        case '/panel/Projects/Progress':
          this.$store.dispatch('loadProjectsProgress');
          break;
      }
    },
    updateCycle: function () {
      switch (this.$route.path) {
        case '/panel/ISF/CycleMaterials/Delivery-Execution':
          this.$store.dispatch('loadCycleDeliveryExecution');
          break;
        case '/panel/ISF/CycleMaterials/Comparison':
          this.$store.dispatch('loadCycleComparison');
          break;
        case '/panel/ISF/CycleMaterials/Progress':
          this.$store.dispatch('loadCycleProgress');
          break;
      }
    },
    updatePrrp: function () {
      switch (this.$route.path) {
        case '/panel/Equipment/Delivery-Execution':
          this.$store.dispatch('loadPrrpDeliveryExecution');
          break;
        default:
          break;
      }
    },
  }
};
</script>

<template lang="pug">
.filters(:key=locale v-if="multiselects.length > 0")
    .filters__row
        Dropdown(
            v-for="multiselect in multiselects",
            :options="multiselect.options",
            :multiselect="multiselect.multiselect",
            v-model="multiselect.value",
            :placeholder="$t(multiselect.placeholder)",
            @update:modelValue="(v)=>updateFilter(v, multiselect.placeholder, section)",
            :value="multiselect.value",
        )
    .clear-filters(@click="(v)=>clearFilter(section)" v-if="!disableAllClear") {{ $t('ClearFilter') }}
</template>

<style lang="scss" scoped>
.clear-filters {
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  margin-left: var(--pdxl);
  text-decoration: underline;
  opacity: .5;
  &:hover {
    opacity: 1;
    color: var(--blue-light);
  }

  @include respond-to(large) {
    order: -1;
    margin-left: 0;
    margin-right: auto;
    margin-top: 9px;
  }
}

.filters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;
  flex-wrap: wrap;
  &__row {
    flex-wrap: wrap;
    display: flex;
    gap: 4px;
    &>*:nth-child(1) {
      margin-right: 28px;
    }
  }
  @include respond-to(large) {
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
</style>
